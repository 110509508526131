import React, {Component} from 'react';
import {Button, Pagination, Popover, Table, ConfigProvider, DatePicker, message} from "antd";
import {withTranslation} from "react-i18next";
import {getDateTime, getDate} from "../../../utils";
import {WorkOrderDocumentsGet, WorkorderInvoiceDiscountUpdate} from "../../../controller/API/salesOperationAPI";
import {profileGetOne} from "../../../controller/API/profileApi";
import WorkOrderDocumentFilter from "../../Profiles/distributors/ViewDistributorProfile/tabs/WorkOrderDocumentFilter";
import {Image as Images} from "../../Images";
import {CalendarOutlined} from '@ant-design/icons';
import frFR from 'antd/lib/locale-provider/fr_FR';

const pagination = Pagination;
pagination.pageSize = 25;
const INVOICE = 'Facture';
const INVOICE_DOCUMENTS = JSON.stringify([INVOICE])
const moment = require('moment');


class DistributorsInvoiceTable extends Component {
    state = {
        invoices: [],
        loading: true,
        pagination: pagination,
        emailModalVisible: false,
        selectedRowKeys: [],
        showDatePicker: 0,
        profile: null,
    };

    handleDateChange = (date, id) => {
        let {params} = this.state;
        params = {'type': INVOICE_DOCUMENTS, ...params};
        WorkorderInvoiceDiscountUpdate(id, {"payment_date": date.format('YYYY-MM-DD')}).then(()=>{
            message.success("Date updated successfully")
            this.fetch(params)
        }).catch(err=>{
            message.error("Something went wrong")
        })
    }

    fetchProfile = () => {
        profileGetOne().then((response) => {
            this.setState({profile: response.data})
        })
    }

    getColumns = () => {
        const {t} = this.props;
        const columns = [
            {
                title: t('distributor_name'),
                dataIndex: 'workorder',
                render: workorder => workorder.client.client_name
            }, {
                title: t('agent_name'),
                dataIndex: 'workorder.sales_representative',
                key: 'sales_representative',
            }, {
                title: t('number'),
                dataIndex: 'workorder.id',
                key: 'workorder_id',
                sorter: true
            }, {
                title: t('Numéro du facture'),
                key: 'id',
                render: data => `F${data.counter}`,
                sorter: true
            }, {
                title: t('total'),
                dataIndex: 'invoice_data.invoice_ht_amount',
                key: 'invoice_ht_amount',
            },{
                title: t('eco_part_amount'),
                dataIndex: 'invoice_data.invoice_eco_part_amount',
                key: 'invoice_eco_part_amount',
            },{
                title: t('ttc_amount'),
                dataIndex: 'invoice_data.invoice_ttc_amount',
                key: 'invoice_ttc_amount',
            },{
                title: t('Date et l’heure'),
                dataIndex: 'invoice_data.invoice_created_at',
                render: invoice_created_at => <span>{getDateTime(invoice_created_at)}</span>
            }, {
                title: t('order_status'),
                dataIndex: 'workorder.status',
                render: status => <a className="text-success" href="#">{t(`${status}`)}</a>,
            },{
                title: t('invoice_due_date'),
                dataIndex: 'invoice_data.invoice_due_date',
                width: 150,
                render: (invoice_due_date, data) => {
                    const currentDate = new Date();
                    const dueDate = new Date(invoice_due_date);

                    const isOverDue = dueDate < currentDate;
                    const textStyle={color: !data?.invoice_data?.invoice_payment_date && isOverDue ? 'red': 'inherit'}

                    return (<span style={textStyle}>{getDate(invoice_due_date)}</span>);
                }
            },{
                title: t('invoice_payment_date'),
                dataIndex: 'invoice_data.invoice_payment_date',
                width: 180,
                render: (payment_date, record) => <div style={{ display: 'flex', alignItems: 'center' }} key={record.id}>
                <span>{payment_date ? getDate(payment_date) : 'Ajouter'}</span>
                <div 
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '24px',
                    height: '24px',
                    borderRadius: '50%',
                    backgroundColor: '#F2F2F2',
                    marginLeft: '8px',
                    cursor: 'pointer'
                  }}
                  onClick={()=> this.setState({showDatePicker: record.counter})}
                >
                  <CalendarOutlined />
                </div>
                    <ConfigProvider locale={frFR}>
                    <DatePicker 
                        onChange={(date)=>this.handleDateChange(date, record.counter)}
                        defaultValue={payment_date ? moment(payment_date, 'YYYY/MM/DD') : moment(new Date, 'YYYY/MM/DD')}
                        open={this.state.showDatePicker === record.counter}
                        onOpenChange={(open) => this.setState({showDatePicker: 0})}
                        format={"YYYY/MM/DD"}
                        style={{ visibility: 'hidden', width: '1%'}}
                    />
                </ConfigProvider>
              </div>
            },
        ];
        return columns
    }


    handleChange = (pagination, filters, sorter) => {
        let {params} = this.state;
        let symbol = sorter.order === 'descend' ? '-' : '';
        params = {...params, page: pagination.current};
        if (sorter.order) {
            params = {...params, ordering: `${symbol}${sorter.columnKey === 'id' ? 'invoice_id' : sorter.columnKey}`}
        }
        this.setState({params});
        this.fetch(params)
    };

    fetch = (params={}) => {
        this.setState({loading: true, params});
        params = {'type': INVOICE_DOCUMENTS, ...params};
        Object.keys(params).forEach(key => {
            if (params[key])
                params[key] = params[key].key ? params[key].key : params[key]
        });
        WorkOrderDocumentsGet(params)
            .then(response => {
                let {pagination} = this.state;
                pagination.total = response.count;
                this.setState({invoices: response.data, pagination, loading: false})
            })
    };

    componentDidMount() {
        this.fetchProfile()
        this.fetch()
    }

    onSelectChange = selectedRowKeys => {
        this.setState({selectedRowKeys});
    };

    render() {
        const {invoices, loading, selectedRowKeys} = this.state
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            hideDefaultSelections: true,
            selections: [
                {
                    key: 'document',
                    text: 'Voir un document',
                    onSelect: changableRowKeys => {
                        let {documents} = this.state
                        selectedRowKeys.forEach(item => {
                            const data = invoices.find(a => a.id === item)
                            if (data)
                                window.open(data.pdf, '_blank')
                        })
                    }
                },
            ]
        }
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row w-100 m-0 common-heading-details mb-3">
                        <div className="col-sm-6 col-md-7 col-12 p-0">
                            <div className="row mx-0 flex-align-center">
                                <h6 className="mb-0 font-weight-bold text-uppercase mr-4 distributors-small-heading">FACTURES</h6>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-5 col-12 p-0">
                            <div className="row mx-0 filter-row">
                                <div className="filter-main-div">
                                    <Popover overlayClassName="filter-main-common work-order-filter"
                                             content={<WorkOrderDocumentFilter invoice={true} onFilter={this.fetch} showComments={false}/>}
                                             title="" trigger="click">
                                        <Button className="ant-dropdown-link border-0"
                                                onClick={e => e.preventDefault()}>
                                            <img alt="filter icon" className="img-fluid"
                                                 src={Images.filter_primary}/>
                                            <span>Recherche avancée</span>
                                        </Button>
                                    </Popover>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row w-100 m-0">
                        <div className="col-12 p-0">
                            <div className="listing-table table-responsive when-pagination-true">
                                <Table className="responsive-table table table-hover table-custom"
                                       dataSource={invoices}
                                       loading={loading}
                                       pagination={pagination}
                                       rowSelection={rowSelection}
                                       rowKey={record => record.id}
                                       onChange={this.handleChange}
                                       columns={this.getColumns()}
                                       size="middle"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default (withTranslation('common')(DistributorsInvoiceTable));
