import React, {Component} from 'react';
import {Button, Pagination, Popover, Table, DatePicker, ConfigProvider, message} from "antd";
import {getDateTime, getDate} from "../../../../utils";
import {sendDocumentEmailPost, WorkOrderInvoiceGet, WorkorderInvoiceDiscountUpdate} from "../../../../controller/API/salesOperationAPI";
import {withTranslation} from "react-i18next";
import {EmailModal} from "./modals/EmailModal";
import WorkOrderDocumentFilter from "./tabs/WorkOrderDocumentFilter";
import {Image as Images} from "../../../Images";
import {distributorsEmailPost} from '../../../../controller/API/profileApi';
import {CalendarOutlined} from '@ant-design/icons';
import frFR from 'antd/lib/locale-provider/fr_FR';
import AppliedFilterBar from '../../../common-component/AppliedFilterBar';

const pagination = Pagination;
pagination.pageSize = 25;
const INVOICE = 'Facture'
const moment = require('moment');


class InvoiceTable extends Component {
    state = {
        invoices: [],
        loading: true,
        pagination: pagination,
        selectedRowKeys: [],
        emailModalVisible: false,
        defaultEmail: [],
        documents: [],
        initialEmail: ['compta@tablacasa.fr'],
        showDatePicker: 0,
        params: {}
    };

    handleEmail = (visible, data = null) => {
        if (visible) {
            distributorsEmailPost(this.props.profile.id)
                .then(response => {
                    this.setState({defaultEmail: response.data})
                })
            this.setState({documents: [data.document.id]})
        } else {
            this.fetch()
        }
        this.setState({emailModalVisible: visible})
    }

    handleDateChange = (date, id) => {
        WorkorderInvoiceDiscountUpdate(id, {"payment_date": date.format('YYYY-MM-DD')}).then(()=>{
            message.success("Date updated successfully")
            this.fetch()
        }).catch(err=>{
            message.error("Something went wrong")
        })
    }

    getColumns = () => {
        const {t} = this.props;
        const columns = [
            {
                title: t('Envoyer par email'),
                render: data => <span onClick={() => this.handleEmail(true, data)}
                                      className="envoyer-btn">Envoyer</span>,
            }, {
                title: t('number'),
                dataIndex: 'workorder.id',
                key: "workorder_id",
                sorter: true
            }, {
                title: t('Numéro du facture'),
                sorter: true,
                key: "id",
                render: data => `F${data.id}`
            }, {
                title: t('Date et l’heure'),
                dataIndex: 'created_at',
                sorter: true,
                key: "created_at",
                render: created => <span>{getDateTime(created)}</span>
            }, {
                title: t('agent_name'),
                dataIndex: 'workorder.sales_representative',
                key: 'sales_representative',
            },{
                title: t('total'),
                dataIndex: 'total_price',
                key: 'total_price',
            },{
                title: t('ttc_amount'),
                dataIndex: 'final_amount',
                key: 'final_amount',
            }, {
                title: t('order_status'),
                dataIndex: 'workorder.status',
                key: "workorder__status",
                sorter: true,
                render: status => <span className="text-success">{t(`${status}`)}</span>,
            }, {
                title: 'Email envoyé le',
                dataIndex: 'document.last_sent_email',
                key: "last_sent_email",
                render: last_sent_email => last_sent_email ? getDateTime(last_sent_email) : "",
            }, {
                title: 'Email',
                dataIndex: 'document.sent_email',
                render: email => email ? email : "",
            },{
                title: t('invoice_due_date'),
                dataIndex: 'invoice_due_date',
                render: (invoice_due_date, data) => {
                    const currentDate = new Date();
                    const dueDate = new Date(invoice_due_date);

                    const isOverDue = dueDate < currentDate;
                    const textStyle={color: !data.payment_date && isOverDue ? 'red': 'inherit'}

                    return (<span style={textStyle}>{getDate(invoice_due_date)}</span>);
                }
            },{
                title: t('invoice_payment_date'),
                dataIndex: 'payment_date',
                render: (payment_date, record) => <div style={{ display: 'flex', alignItems: 'center' }} key={record.id}>
                <span>{payment_date ? getDate(payment_date) : 'Ajouter'}</span>
                <div 
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '24px',
                    height: '24px',
                    borderRadius: '50%',
                    backgroundColor: '#F2F2F2',
                    marginLeft: '8px',
                    cursor: 'pointer'
                  }}
                  onClick={()=> this.setState({showDatePicker: record.id})}
                >
                  <CalendarOutlined />
                </div>
                    <ConfigProvider locale={frFR}>
                    <DatePicker 
                        onChange={(date)=>this.handleDateChange(date, record.id)}
                        defaultValue={payment_date ? moment(payment_date, 'YYYY/MM/DD') : moment(new Date, 'YYYY/MM/DD')}
                        open={this.state.showDatePicker === record.id}
                        onOpenChange={(open) => this.setState({showDatePicker: 0})}
                        format={"YYYY/MM/DD"}
                        style={{ visibility: 'hidden', width: '1%'}}
                    />
                </ConfigProvider>
              </div>
            },
        ];
        return columns
    }

    onSelectChange = selectedRowKeys => {
        this.setState({selectedRowKeys});
    };

    handleChange = (pagination, filters, sorter) => {
        let {params} = this.state;
        let symbol = sorter.order === 'descend' ? '-' : '';
        params = {...params, page: pagination.current};
        if (sorter.order) {
            params = {...params, ordering: `${symbol}${sorter.columnKey}`}
        }
        this.setState({params});
        this.fetch(params)
    };

    fetch = (params={}) => {
        this.setState({loading: true, params: {...params}});
        if (params['client'] === undefined){
            params['client'] = this.props.profile.id
        }
        params = {...params};
        Object.keys(params).forEach(key => {
            if (params[key])
                params[key] = params[key].key ? params[key].key : params[key]
        });
        ['invoice_range', 'date_de_creation', 'workorder_status'].forEach(key => delete params[key])
        WorkOrderInvoiceGet(params)
            .then(response => {
                let {pagination} = this.state;
                pagination.total = response.data.count;
                this.setState({invoices: response.data.results, pagination, loading: false})
            })
    };

    componentDidMount() {
        this.fetch()
    }

    getFilterData() {
        const { t } = this.props;
        let { params } = this.state;
        if (Object.keys(params).length) {
            if (params['id__lte']) {
                params['invoice_range'] = `${params['id__gte']} - ${params['id__lte']}`;
            }
            if (params['created_at__gte']) {
                params['date_de_creation'] = `${params['created_at__gte']} - ${params['created_at__lte']}`;
            }
            if (params['status']) {
                let status;
                if (params['status'].startsWith('[')) {
                    status = JSON.parse(params['status']);
                } else {
                    status = params['status'].split(',').map(item => item.trim());
                }
                const modifiedStatus = status.map(d =>t(d));
                const result = modifiedStatus.join(', ');
                params['workorder_status'] = result;
 
            }
            return {
                params,
                'names': {
                    'client': 'Nom du distributeur',
                    'sales_rep': t('representative'),
                    'invoice_range': 'Numéro du facture',
                    'date_de_creation': 'Date de création',
                    'workorder_status': t('status'),
                },
            }
        } else
            return { params }
    };

    render() {
        const {selectedRowKeys, initialEmail} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            hideDefaultSelections: true,
            selections: [
                {
                    key: 'liverson',
                    text: 'Voir une facture',
                    onSelect: changableRowKeys => {
                        let {invoices} = this.state
                        selectedRowKeys.forEach(item => {
                            const data = invoices.find(a => a.id === item)
                            if (data && data.document)
                                window.open(data?.document?.pdf, '_blank')
                        })
                    }
                },
            ],
        };

        const {invoices, loading, emailModalVisible, defaultEmail, documents} = this.state
        return (
            <React.Fragment>
                <div className="row summary-info-row mx-0">
                    <AppliedFilterBar {...this.getFilterData()} distributor={true}/>
                    <div className="col-12">
                        <div className="row mb-3 align-items-center justify-content-between">
                            <h6 className="text-uppercase font-weight-bold mb-0">{INVOICE} </h6>
                            <div className="filter-main-div work-order-filter-main-div">
                                <Popover overlayClassName="profile-filter profile-filter-update filter-main-common"
                                         content={<WorkOrderDocumentFilter onFilter={this.fetch} distributorInvoice={true} showComments={false}/>}
                                         title="" trigger="click">
                                    <Button className="ant-dropdown-link border-0"
                                            onClick={e => e.preventDefault()}>
                                        <img alt="filter icon" className="img-fluid"
                                             src={Images.filter_primary}/>
                                        <span>Recherche avancée</span>
                                    </Button>
                                </Popover>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 p-0">
                        <div className="listing-table table-responsive">
                            <Table className="responsive-table table table-hover table-custom"
                                   rowSelection={rowSelection}
                                   dataSource={invoices}
                                   loading={loading}
                                   pagination={pagination}
                                   rowKey={record => record.id}
                                   onChange={this.handleChange}
                                   columns={this.getColumns()}/>
                        </div>
                    </div>
                </div>
                <EmailModal initialEmail={initialEmail} defaultEmail={defaultEmail} visible={emailModalVisible}
                            sendAPI={sendDocumentEmailPost}
                            documents={documents} onClose={() => this.handleEmail(false)}/>
            </React.Fragment>
        );
    }
}

export default withTranslation('common')(InvoiceTable);
